import ButtonOption from './ButtonOption';
import React, { useState } from "react";
import { map, equals, prop, isNil } from 'ramda';

export default function ({ options = [], onOptionSelected }) {
  const [optionSelected, setOptionSelected] = useState(null);

  const onOptionSelectedClick = (option) => {
    setOptionSelected(option);
    onOptionSelected(option);
  };

  const getIsSelectedValue = (option) => {
    return isNil(optionSelected) ? null : equals(prop('uuid', option), prop('uuid', optionSelected));
  };

  return (
    <div className="flex flex-wrap -mx-2 justify-center">
      {
        map(
          option => (
            <div className="w-full md:w-1/2 px-2 mb-2">
              <ButtonOption
                isSelected={getIsSelectedValue(option)}
                option={option}
                className="mb-2"
                onSelect={onOptionSelectedClick}
                key={option.uuid}
              />
            </div>
          ),
          options
        )
      }
    </div>
  );
}
