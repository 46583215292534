import React from "react";

export default function ({ children, onClick, disabled }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`
        block uppercase mx-auto shadow bg-red-600  focus:outline-none text-white py-4 px-12 rounded
        ${ disabled ? 'opacity-40' : 'hover:bg-red-500 focus:shadow-outline'}
      `}>
      { children }
    </button>
  );
}
