import {useMemo} from "react";
import axios from "axios";
import { useMutation } from "react-query";
import env from '../env/enviroment';


export default function usePollEvent() {

  const token = useMemo ( () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get('token');
  },[]);

  const validateAccess = useMutation(
      async ({dni, memberNumber}) => 
        axios.get(`${env.baseUrl}poll/ballots/${token || ''}?doc_id=${dni}&m_number=${memberNumber}`)
    )

  return {
    ...validateAccess,
    token
  };
}
