import Loader from "./Loader";
import React from "react";


export default function () {
  return (
    <div className="flex justify-center items-center h-full py-32">
      <div className="text-center">
        <Loader/>
        <p className="text-xs text-white">validando...</p>
      </div>
    </div>
  );
}
