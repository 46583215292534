import React from "react";


export default function ({ option = {}, className = '', onSelect = () => {}, isSelected = null }) {
  const onOptionSelect = () => {
    onSelect(option);
  };
  const getIsSelectedClass = () => {
    return isSelected === false ? 'opacity-40' : 'opacity-100';
  };
  return (
    <div
      className={`${className} bg-white rounded-md p-2 font-bold text-md cursor-pointer select-none ${getIsSelectedClass()}`}
      onClick={onOptionSelect}
    >
      <div className="flex flex-wrap -mx-2">
        {
          option?.image && (
            <div className="w-1/4 px-2">
              <img src={option?.image} />
            </div>
          )
        }
        <div className={`${option?.image ? 'w-3/4' : 'w-full' } px-2 flex items-center`}>
          <div dangerouslySetInnerHTML={{ __html: option?.content }}></div>
        </div>
      </div>
    </div>
  );
}
