import React, { useState } from "react";
import Statement from "./Statement";
import ButtonOptionsList from "./ButtonOptionsList";
import Button from "./Button";
import LoadingPage from "./LoadingPage";
import { isNil, pathOr } from 'ramda';
import {useAdminTheme} from "../hooks/useAdminTheme";
import useSaveVote from "../hooks/useSaveVote";

export default function ({ pollingInfo, onSavedVoting, onFailVoting, token, memberInfo = {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const { gi } = useAdminTheme();

  const {mutateAsync: saveVote} = useSaveVote()

  const saveOption = () => {
    setIsLoading(true);
      saveVote({token, pollingInfo, memberInfo, optionSelected}).then(
        response => {
          setIsLoading(false);
          onSavedVoting();
        }
      )
      .catch(error => {
        setIsLoading(false);
        onFailVoting()
      })
  };

  return (
    isLoading ? <LoadingPage/> :
    <div>
      <div className="my-2 mb-8">
        <Statement statementContent={pathOr('', ['election', 'question'], pollingInfo)} />
      </div>
      <div>
        <ButtonOptionsList options={pathOr([], ['election', 'options_list'], pollingInfo)} onOptionSelected={setOptionSelected}/>
      </div>
      <div className="mt-6">
        <Button
          disabled={isNil(optionSelected)}
          onClick={saveOption}>{ gi('send-poll-button') }</Button>
      </div>
    </div>
  );
}
