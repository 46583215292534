const React = require('react');
const R = require('ramda');
const data = require('./dataAdminProvider');

const AdminThemeContext = React.createContext({});

export const AdminThemeProvider = ({ defaultType = 'poll', children }) => {
  const [adminType, setAdminType] = React.useState(process.env.REACT_APP_POLL_TYPE);

  const getInformation = (field) => {
    return R.pathOr('-', [adminType, field], data);
  };

  const themeData = React.useMemo(() => ({
    adminType: adminType,
    gi: getInformation
  }), [adminType]);

  return <AdminThemeContext.Provider value={themeData}>
    { children }
  </AdminThemeContext.Provider>
};

export const useAdminTheme = () => {
  const context = React.useContext(AdminThemeContext);
  if (!context) {
    throw Error('AdminThemeProvider is required');
  }
  return context;
};
