import LoaderImage from '../Spin-1s-200px.gif';
import React from "react";

export default function () {
  return (
    <div>
      <img className="w-16" src={LoaderImage} />
    </div>
  )
}
