import React from "react";


export default function ({ event = {} }) {
  return (
    <div className="p-2  bg-white rounded-md text-sm">
      <div className="flex">
        <div className="hidden md:block">
          <img src="/logo-club.png" className="w-32"/>
        </div>
        <div className="p-2">
          <p className="font-bold">{ event?.name }</p>
          <div dangerouslySetInnerHTML={{ __html: event?.description }}></div>
        </div>
      </div>
    </div>
  );
}
