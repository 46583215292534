import React from 'react';

export default function ({ children }) {
  return (
    <div className="p-2 bg-white rounded-md text-center h-full flex items-center justify-center">
      <div>
        <span className="font-bold">Tiempo restante</span>
        <div className="countdown-wrapper">
          { children }
        </div>
      </div>
    </div>
  )
}
