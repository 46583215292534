import './App.css';
import PollingPage from './components/PollingPage';
import React, { useState } from "react";
import AlertWarning from "./components/alerts/AlertWarning";
import LoginForm from "./components/LoginForm";
import { useAdminTheme } from './hooks/useAdminTheme';

function App() {
  const [isTokenValidated, setIsTokenValidated] = useState(false);
  const [hasErrorValidation, setHasErrorValidation] = useState(false);
  const [token, setToken] = useState('');
  const [pollingInfo, setPollingInfo] = useState({});
  const [memberInfo, setMemberInfo] = useState({});
  const { gi } = useAdminTheme();

  const onSuccessValidation = ({memberInfo, token, pollingInfo}) => {
    setToken(token);
    setMemberInfo(memberInfo);
    setPollingInfo(pollingInfo);
    setIsTokenValidated(true);
  };
  const onErrorValidation = () => {
    setHasErrorValidation(true);
  };

  return (
    <div className="px-4 main-bg" style={{backgroundColor: '#465b6f', backgroundImage: 'url(https://intersocio.clubinter.org.pe/assets/img/login/bg-1.jpg)'}}>
      <div className="main-page">
        <div>
          {
            !isTokenValidated ? (
              <>
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full md:w-1/2 px-4 flex items-center mb-6">
                    <div>
                      <h2 className="mb-10 text-white text-2xl text-center font-bold ">{ gi('page-title') }</h2>
                      <div className="intersocio-brand">
                        <div className="logos" style={{backgroundImage: 'url(https://intersocio.clubinter.org.pe/assets/img/login/bg-logos.png)'}}/>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <p className="text-center text-white mb-4">Ingresa la información requerida para que podramos validar tu identidad.</p>
                    <LoginForm onSuccessValidation={onSuccessValidation} onErrorValidation={onErrorValidation}/>
                    {
                      hasErrorValidation && <div> <AlertWarning title="Cuidado!" message="Al parecer las credenciales usadas no son válidas para realizar esta transacción." /> </div>
                    }
                  </div>
                </div>
              </>
            ) : <PollingPage pollingInfo={pollingInfo} token={token} memberInfo={memberInfo}/>
          }
        </div>
      </div>
    </div>
  );
}

export default App;
