import React, { useState } from "react";
import usePollEvent from "../hooks/usePollEvent";

export default function ({ onSuccessValidation, onErrorValidation }) {
  const { mutateAsync: validateAccess, token } =  usePollEvent();
  const [memberInfo, setMemberInfo] = useState({dni: '', memberNumber: ''});
  const [loading, setLoading] = useState(false);

  const updateMemberInfo = field => event => {
    const re = /^[0-9\b]+$/;
    const { value } = event.target;
    if (value === '' || re.test(value)) {
      setMemberInfo({...memberInfo, [field]: value});
    }
  };
  
  const validateInfo = () => {
    setLoading(true);
    validateAccess(memberInfo)
      .then( response => {
        onSuccessValidation({memberInfo, token, pollingInfo: response.data });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        onErrorValidation(error);
      })
  };

  return (
    <div className="bg-white shadow rounded-lg divide-y divide-gray-200 w-72 mx-auto">
      <div className="px-5 py-7">
        <label className="font-semibold text-sm text-gray-600 pb-1 block">DNI</label>
        <input
          type="text"
          className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
          value={memberInfo.dni} onChange={updateMemberInfo('dni')}
          maxLength={12}
        />
        <label className="font-semibold text-sm text-gray-600 pb-1 block">Número de socio</label>
        <input
          type="text"
          className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
          maxLength={5}
          value={memberInfo.memberNumber} onChange={updateMemberInfo('memberNumber')}
        />
        <button
          onClick={validateInfo}
          disabled={!memberInfo.memberNumber || !memberInfo.dni || loading}
          type="button"
          className="transition duration-200 bg-red-500 hover:bg-red-600 focus:bg-red-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
          <span className="inline-block mr-2">
             { loading ? 'Validando...' : 'Validar mi información'}
          </span>
        </button>
      </div>
    </div>
  );
}
