import { useMutation } from "react-query";
import axios from "axios";
import { pathOr, prop } from 'ramda';
import env from '../env/enviroment';

export default function useSaveVote() {
    return useMutation(({memberInfo, optionSelected, pollingInfo, token})=>
        axios.post(`${env.baseUrl}poll/vote?doc_id=${memberInfo.dni}&m_number=${memberInfo.memberNumber}`, {
        vote: { option_uuid: prop('uuid', optionSelected), option: prop('name', optionSelected)},
        ballot_id: pathOr('', ['ballot', 'uuid'], pollingInfo),
        token
        })
    )
}
