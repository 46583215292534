import React, { useCallback, useState } from 'react';
import Countdown from 'react-countdown';
import SessionInfo from './SessionInfo';
import EventInfo from './EventInfo';
import Polling from './Polling';
import AlertSuccess from './alerts/AlertSuccess';
import AlertDanger from "./alerts/AlertDanger";
import CountDownWrapper from './countDown/CountDownWrapper';
import { path } from 'ramda';

export default function({ pollingInfo = {}, token, memberInfo = {} }) {
  const expirationDate = new Date(path(["ballot", "expired_at"], pollingInfo));
  const [successVote, setSuccessVote] = useState(false);
  const [errorVote, setErrorVote] = useState(false);
  const [expired, setExpired] = useState(expirationDate < Date.now());
  
  const countdownRenderer = ({formatted: {minutes, seconds}}) => {
    return (
      <span>
        {minutes}:{seconds}
      </span>
    ); 
  }

  return (
    <div>
      <div className="flex flex-wrap -mx-1 mb-2 justify-center">
        <div className={`w-full md:w-9/12 px-1 mb-2`}>
          <EventInfo event={{
            name: path(['election', 'name'], pollingInfo),
            description: path(['election', 'description'], pollingInfo),
          }} />
        </div>
        {
          !expired && (
            <div className="w-full md:w-3/12 px-1 mb-2">
              <CountDownWrapper>
                <Countdown date={new Date(path(["ballot", "expired_at"], pollingInfo))} onComplete={()=>setExpired(true)} renderer={countdownRenderer} />
              </CountDownWrapper>
            </div>
          )
        }
      </div>
      <div className="w-full p-4 rounded-md">
        {
          (!successVote && !errorVote && !expired) &&  <Polling
            pollingInfo={pollingInfo}
            onSavedVoting={() => setSuccessVote(true)}
            onFailVoting={() =>setErrorVote(true)}
            token={token}
            memberInfo={memberInfo}
          />
        }
        { successVote && <AlertSuccess title="Todo bien" message="Hemos guardado tu voto, gracias" /> }
        { errorVote && <AlertDanger title="Hubo un problema" message="No pudimos guardar tu voto, intentalo más tarde o refresca la página." /> }
        { expired && <AlertDanger title="Tiempo expirado" message="El tiempo para poder registrar su participación expiró, por favor solicite una nueva cedula en InterSocio." />}
      </div>
    </div>
  );
}
